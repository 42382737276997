import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import shortid from 'shortid';
import ReactSelect from 'react-select';
import moment from 'moment-timezone';
import {
  Button, Row, Form,
} from 'react-bootstrap';
// Services
import {
  createActivity, updateActivity, getActivities, getPatientInfo,
} from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT, AUDITING_TITLE_OPTIONS, CALL_ATTEMPTS_OPTIONS,
} from '../../../constants/constants';
import { TIME_TRACKING_NOTES } from '../../../constants/templates';
import { AUDITING_PAGE_SIZE } from '../../../constants/pageSizes';
// Components
import { Select } from '../../base/forms/Select';
import { Checkbox } from '../../base/forms/Checkbox';
import { TextArea } from '../../base/forms/TextArea';
import { TextInput } from '../../base/forms/TextInput';
// Custom Hooks
import useConditionalFetch from '../../../hooks/useConditionalFetch';

export function AuditingModal(props) {
  const {
    patientCanBeBilled, initialActivity, isModalOpen, showNotification,
    setIsModalOpen, timezone, callbackAction = null, patientId, updatePatient,
  } = props;

  const DEFAULT_ACTIVITY = {
    id: null,
    title: '',
    notes: '',
    billed: null,
    cnName: null,
    duration: 0,
    billable: patientCanBeBilled,
    directContactIsMade: false,
    date: moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER_WITH_TIME),
    permissions: {},
  };

  const [shouldFetch, setShouldFetch] = useState(false);
  const [newActivity, setNewActivity] = useState(initialActivity || DEFAULT_ACTIVITY);

  const { data: patientInfoData } = useConditionalFetch(
    () => getPatientInfo(patientId), shouldFetch,
  );

  const loadPatientAuditing = () => {
    const {
      auditing: { currentPage = 0 } = {},
    } = props;
    const params = {
      pageSize: AUDITING_PAGE_SIZE,
      pageNumber: currentPage,
    };
    const getActivitiesRequest = getActivities(patientId, params);
    const getActivitiesPromise = getActivitiesRequest.promise;

    getActivitiesPromise.then((data) => {
      delete getActivitiesRequest.promise;
      updatePatient({ auditing: data || {} });
    }).catch((error) => {
      delete getActivitiesRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient tracking data, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    loadPatientAuditing();
  }, []);

  useEffect(() => {
    if (patientInfoData) updatePatient(patientInfoData);
    setShouldFetch(false);
  }, [patientInfoData]);

  useEffect(() => {
    if (initialActivity) {
      setNewActivity({
        id: initialActivity.id || '',
        title: initialActivity.title || '',
        notes: initialActivity.notes || '',
        billed: initialActivity.billed || null,
        cnName: initialActivity.cnName || null,
        duration: initialActivity.duration || 0,
        billable: initialActivity.billable || patientCanBeBilled,
        directContactIsMade: initialActivity.directContactIsMade || false,
        date: initialActivity.date || moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER_WITH_TIME),
        permissions: initialActivity.permissions || null,
      });
    } else {
      setNewActivity(DEFAULT_ACTIVITY);
    }
  }, [initialActivity]);

  const getBillableBlock = (activity) => {
    if (activity.billed) {
      return <span className="badge badge-success text-uppercase px-3 py-1">Billed</span>;
    } if (activity.billable) {
      return <span className="badge badge-ccm-orange text-uppercase px-3 py-1">Billable</span>;
    }
    return null;
  };

  const handleCloseModal = () => {
    if (callbackAction) callbackAction();
    setIsModalOpen(false);
  };

  const saveActivity = (activity) => {
    const isActivityNew = !activity.id;
    const uniqueKey = shortid.generate();

    const addActivityRequest = isActivityNew
      ? createActivity(patientId, { ...activity, uniqueKey }) : updateActivity(activity);
    const addActivityPromise = addActivityRequest.promise;

    return addActivityPromise.then(() => {
      delete addActivityPromise.promise;
      loadPatientAuditing();
      if (CALL_ATTEMPTS_OPTIONS.includes(activity.title)) {
        setShouldFetch(true);
      }
      if (callbackAction) callbackAction();
      handleCloseModal();
    }).catch((error) => {
      delete addActivityPromise.promise;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this goal',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const fieldStyles = {
    formGroup: 'mb-0',
    formLabel: 'd-none',
  };
  const durationStyles = {
    formControl: 'text-right border-bottom-0',
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Auditing Activities Modal"
      data-test="auditingModal_modal"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {`${initialActivity ? 'Edit' : 'Add'} Time Tracking Entry`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="auditingModal_modalCloseButton"
          />
        </div>
        <Formik
          initialValues={newActivity}
          validationSchema={Yup.object({
            notes: Yup.string(),
            title: Yup.string().required('Required'),
            duration: Yup.number()
              .required('Required')
              .min(0, 'Must be greater than 0 minutes')
              .max(120, 'Must be less than 120 minutes'),
          })}
          onSubmit={(values, { resetForm }) => {
            const formattedDate = moment(values.date).format(DATE_FORMAT.FULL_SERVER);
            const updatedValues = { ...values, date: formattedDate };
            saveActivity(updatedValues);
            resetForm();
          }}
          data-test="auditingModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <Row className="align-items-center">
                  {newActivity.cnName && (
                  <div className="col">
                    <Form.Group>
                      <Form.Label>CN</Form.Label>
                      <p>{newActivity.cnName}</p>
                    </Form.Group>
                  </div>)}
                  <div className="col">
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <p>
                        {moment(newActivity.date, DATE_FORMAT.FULL_SERVER_WITH_TIME)
                          .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)}
                      </p>
                    </Form.Group>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col" data-test="auditingModal_reasonsSelect">
                    <Select
                      name="title"
                      options={AUDITING_TITLE_OPTIONS}
                      styles={fieldStyles}
                      disabled={newActivity.billed}
                    />
                  </div>
                  <div className="col text-right">
                    <Checkbox
                      label={formik.values.directContactIsMade ? 'Direct' : 'Not direct'}
                      name="directContactIsMade"
                      type="switch"
                      styles={fieldStyles}
                    />
                  </div>
                  <div className="col text-right">
                    {getBillableBlock(formik.values)}
                  </div>
                  <div className="col text-right">
                    <div className="d-flex align-items-center">
                      <TextInput
                        type="number"
                        min="1"
                        max="120"
                        name="duration"
                        styles={{ ...fieldStyles, ...durationStyles }}
                        data-test="auditing_durationField"
                        disabled={newActivity.permissions.canTimeBeEdited === false}
                      />
                      <span>min</span>
                    </div>
                  </div>
                </Row>
                <Row className="align-items-center mt-3">
                  <div className="col pt-1">
                    <TextArea
                      label="Notes"
                      name="notes"
                      rows={10}
                      data-test="auditing_notesField"
                    />
                    <div
                      className="position-absolute"
                      style={{ top: 0, right: 15, width: 200 }}
                    >
                      <ReactSelect
                        name="template"
                        className="border-bottom-0"
                        placeholder="Add a custom template"
                        searchable
                        clearable={false}
                        options={TIME_TRACKING_NOTES}
                        onChange={ev => formik.setFieldValue(
                          'notes', ev && `${formik.values.notes.length ? `${formik.values.notes}\n\n` : ''}${ev.value}`,
                        )}
                        data-test="auditingModal_templateSelect"
                      />
                    </div>
                    <Button
                      size="sm"
                      variant="link-dark"
                      className="position-absolute"
                      style={{ bottom: 20, right: 15 }}
                      onClick={() => formik.setFieldValue('notes', '')}
                      disabled={!formik.values.notes.length}
                      data-test="auditingModal_trashBtn"
                    >
                      <i className="d-flex-center bi-trash" />
                    </Button>
                  </div>
                </Row>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="auditingModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="auditingModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export function mapStateToProps(state) {
  const patientPermissions = state.patient.permissions || {};

  return {
    timezone: state.tenant && state.tenant.timezone,
    auditing: state.patient && state.patient.auditing,
    patientCanBeBilled: patientPermissions.canBeBilled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditingModal);
