// libraries
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
// constants
import { LOCAL_STORAGE_TIMER_KEY, USER_ROLES } from '../../constants/constants';

// Components
import { withRouter } from '../shared/WithRouter';


const INIT_TIME_FORMAT = '00:00';
const TOTAL_MAX_TIME = '999:59';
const MAX_TIME = '99:59';

const numberFormat = (number, length = 2) => (`0${number}`).slice(-(length));

const getFormattedTime = (time) => {
  const seconds = Math.floor(time / 1000) % 60;
  const minutes = Math.floor(time / 60000) % 999;
  const minutesLength = minutes > 99 ? 3 : 2;

  return minutes > 999 ? TOTAL_MAX_TIME : `${numberFormat(minutes, minutesLength)}:${numberFormat(seconds)}`;
};

export class Stopwatch extends Component {
  state = {
    isTimerOn: false,
    savedTime: 0,
    formattedTime: INIT_TIME_FORMAT,
    isFullShown: false,
  };

  timer = null;

  componentDidMount() {
    const localStorageTimer = this.getTimerDataFromLocalStorage();
    const patient = this.getPatientKey();
    const savedTime = (localStorageTimer && localStorageTimer[patient]) || 0;

    if (savedTime) {
      this.setState({
        savedTime,
        isTimerOn: false,
        isFullShown: true,
        formattedTime: getFormattedTime(savedTime),
      });

      localStorage.setItem(LOCAL_STORAGE_TIMER_KEY, this.getTimerData());
    }

    // save data if a user reloads a page
    window.addEventListener('beforeunload', this.setTimerDataInLocalStorage);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.setTimerDataInLocalStorage);
  }

  getPatientKey = () => {
    const { params: { id: patientId } } = this.props;
    return `patient${patientId}`;
  };

  getTimerDataFromLocalStorage = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_TIMER_KEY));

  setTimerDataInLocalStorage = () => {
    const { isTimerOn } = this.state;

    if (isTimerOn) {
      localStorage.setItem(LOCAL_STORAGE_TIMER_KEY, this.getTimerData());
    }
  };

  getTimerData = () => {
    const { isTimerOn, savedTime } = this.state;
    const patient = this.getPatientKey();
    const timerDataFromLocalStorage = this.getTimerDataFromLocalStorage();

    return JSON.stringify({
      ...timerDataFromLocalStorage,
      [patient]: isTimerOn ? savedTime : null,
    });
  };

  startTimer = () => {
    this.setState(state => ({
      isTimerOn: true,
      savedTime: state.savedTime,
      timerStart: moment().diff(state.savedTime),
      isFullShown: true,
    }));

    this.setTimer();
  };

  stopTimer = () => {
    clearInterval(this.timer);
    this.setState({ isTimerOn: false });
  };

  resetTimer = () => {
    clearInterval(this.timer);
    this.setState({
      isTimerOn: false,
      savedTime: 0,
      formattedTime: INIT_TIME_FORMAT,
    }, this.showFullTimer);
  };

  setTimer = () => {
    this.timer = setInterval(() => {
      const savedTime = moment().diff(this.state.timerStart);
      const formattedTime = getFormattedTime(savedTime);

      this.setState({
        savedTime,
        formattedTime,
      }, (formattedTime === MAX_TIME || formattedTime === TOTAL_MAX_TIME) ? this.stopTimer : null);
    }, 10);
  };

  showFullTimer = () => {
    this.setState(state => ({
      ...state,
      isFullShown: !state.isFullShown,
    }));
  };

  render() {
    const { userRole } = this.props;
    const isCnUser = (userRole === USER_ROLES.CN);
    const isPesUser = (userRole === USER_ROLES.PES);
    const { isFullShown, formattedTime, isTimerOn } = this.state;
    let renderBlock = (
      <Button
        size="sm"
        className="stopwatch-timer rounded-circle mr-3"
        variant="outline-primary"
        onClick={this.startTimer}
      >
        <i className="bi-stopwatch" />
      </Button>
    );

    if (isFullShown) {
      renderBlock = (
        <div>
          <Button
            size="lg"
            variant="link"
            onClick={isTimerOn ? this.stopTimer : this.startTimer}
          >
            {formattedTime}
          </Button>
          <Button
            size="sm"
            className="stopwatch-timer rounded-circle mr-3"
            variant="outline-primary"
            onClick={this.resetTimer}
          >
            <i className="bi-x" />
          </Button>
        </div>
      );
    }
    if (isCnUser || isPesUser) {
      return (
        <Fragment>
          {renderBlock}
        </Fragment>);
    }
    return (<></>);
  }
}

export default withRouter(Stopwatch);
