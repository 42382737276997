import { useEffect } from 'react';
import useVersion from '../hooks/useVersion';

export const rumConfig = async () => {
  // Get the version from the app
  const version = await useVersion().getVersion();
  return {
    version,
    region: 'us-east-1',
    identityId: '544ea3cb-832d-4a0f-a88e-13c4056c3ae3',
    identityPoolId: 'us-east-1:78214093-cfd7-404b-9ed5-56396e01a550',
    sessionSampleRate: 1, // Set to 0 to disable RUM
    sessionEventLimit: 500,
  };
};

export async function recordPageView(location) {
  const pathParts = location.pathname.split('/').filter(Boolean);
  const tenantId = pathParts[0]; // Get the first part of the path as the tenant ID
  pathParts.shift(); // Remove the tenant ID from the path
  const pathWithoutTenant = `/${pathParts.join('/')}`;
  const pageId = pathWithoutTenant.replace(/\/\d+(?=\/|$)/g, '/id');
  const isSearch = location.search?.length > 0;
  const ccmVersion = await useVersion().getVersion();

  const pageViewData = {
    pageId,
    pageAttributes: {
      isSearch,
      tenantId,
      ccmVersion,
    },
  };

  try {
    // Log a page view event with the extracted information
    cwr('recordPageView', pageViewData);
  } catch (error) {
    // Ignore errors to AWS RUM
  }
}

export const AmazonRum = () => {
  useEffect(() => {
    async function addRum() {
      const config = await rumConfig();
      // Don't load the RUM client if session sample rate is 0 or if we're running locally
      if (config.sessionSampleRate > 0 && window.location.hostname !== 'localhost') {
        // Script to load the AWS RUM client via CDN
        return `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
          'cwr',
          '${config.identityId}',
          '${config.version}',
          '${config.region}',
          'https://client.rum.us-east-1.amazonaws.com/1.19.0/cwr.js',
          {
            sessionSampleRate: ${config.sessionSampleRate},
            identityPoolId: '${config.identityPoolId}',
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            telemetries: ['http', 'errors', 'performance'],
            allowCookies: true,
            enableXRay: false,
            sessionEventLimit: ${config.sessionEventLimit},
          }
        );
        `;
      }
      return '';
    }

    const rumScript = document.createElement('script');
    rumScript.async = true;
    addRum().then((result) => {
      rumScript.innerHTML = result;
      document.head.appendChild(rumScript);
    });

    return () => {
      document.head.removeChild(rumScript);
    };
  }, []);

  return null;
};

export default AmazonRum;
