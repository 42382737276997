import packageJson from '../../package.json';

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA?.split(/\./g);
  const versionsB = versionB?.split(/\./g);

  while (versionsA?.length || versionsB?.length) {
    const a = Number(versionsA?.shift());
    const b = Number(versionsB?.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;

    return a > b || Number.isNaN(Number(b));
  }
  return false;
};

export default function useVersion() {
  const getVersion = async () => fetch('/meta.json', {
    cache: 'no-store',
    headers: {
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
  })
    .then(response => response.json())
    .then(meta => meta.version);

  const isLatestVersion = async () => {
    const localStorageKey = 'IS_PAGE_UPDATED';

    return getVersion()
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          const isPageUpdated = JSON.parse(localStorage.getItem(localStorageKey));

          if (!isPageUpdated) {
            window.location.reload();
            localStorage.setItem(localStorageKey, true);
          }
          return false;
        }
        return true;
      });
  };

  return { getVersion, isLatestVersion };
}
